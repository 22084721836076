<template>
  <div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="批次ID">
        <el-input v-model="dataForm.batchId" placeholder="批次ID" clearable></el-input>
      </el-form-item>
      <el-form-item label="UID">
        <el-input v-model="dataForm.uid" placeholder="UID" clearable></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="dataForm.status" clearable placeholder="all">
          <el-option v-for="item in statusOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建日期">
        <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        align="right">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>
        <el-button @click="uploadAnchor()" type="danger">批量导入</el-button>
        <el-button @click="exportList()" type="primary" >导出</el-button>
        <el-button @click="stopAllTask()" type="danger">终止所有未发放任务</el-button>
        <!-- <el-button @click="exportList()"  style="background-color: #00BFBF; color: #fff;">导出</el-button> -->
      </el-form-item>
    </el-form>

    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%; ">

      <el-table-column prop="batchId" header-align="center" align="center" label="BATCHID">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.batchId ? '/' : scope.row.batchId }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="uid"  header-align="center" align="center" label="UID">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.uid ? '/' : scope.row.uid }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="val"  header-align="center" align="center" label="灵力值">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.val ? '/' : scope.row.val }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="taskDesc"  header-align="center" align="center" label="流水描述">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.taskDesc ? '/' : scope.row.taskDesc }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="expiredTime"  header-align="center" align="center" label="过期时间">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.expiredTime ? '/' : scope.row.expiredTime }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="opName" header-align="center" align="center" label="操作人">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.opName ? '/' : scope.row.opName }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="status"  sortable header-align="center" align="center" label="状态">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.status === 0" type="primary">待发放</el-tag>
            <el-tag v-if="scope.row.status === 1" type="primary">已发放</el-tag>
            <el-tag v-if="scope.row.status === -1" type="primary">发放失败</el-tag>
            <el-tag v-if="scope.row.status === 2" type="primary">已终止</el-tag>

          </div>
        </template>
      </el-table-column>
      <el-table-column prop="atime" header-align="center" align="center" label="创建时间">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.atime ? '/' : scope.row.atime }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="finishTime" header-align="center" align="center" label="完成时间">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.finishTime ? '/' : scope.row.finishTime }}
                    </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
                   :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                   layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <upload-anchor v-if="uploadAnchorVisible" ref="uploadAnchor" @refreshDataList="getDataList"></upload-anchor>

  </div>
</template>
<script>
import { langOpt, pickerOptions } from "@/views/common/commonOpt";
import uploadAnchor from "./upload-anchor.vue";


const sourceDataForm = {
  id: '',
  uid: '',
  batchId: '',
  val: '',
  opName:'',
  status:'',
  taskDesc:'',
  atime:'',
  expiredTime:'',
  finishTime:'',
  createTimeArr: []
}
export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      langOpt: langOpt,
      pickerOptions: pickerOptions,
      addOrUpdateVisible: false,
      uploadAnchorVisible:true,
      statusOpt: [
        {
          value: 0,
          label: '待发送'
        },
        {
          value: 1,
          label: '已发送'
        },
        {
          value: 2,
          label: '已终止'
        },
        {
          value: -1,
          label: '发送失败'
        }
      ]

    }
  },
  components: {
    uploadAnchor
  },
  activated() {
    this.getDataList()
  },
  methods: {
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/walletTask/list'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
          endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
          status:this.dataForm.status,
          uid: this.dataForm.uid,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.result.data
          this.totalPage = data.result.last_page
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    stopAllTask(){
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/walletTask/stop'),
        method: 'get',
        data: this.$http.adornData({
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message.info("成功终止")
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    uploadAnchor(){
      this.uploadAnchorVisible = true
      this.$nextTick(() => {
        this.$refs.uploadAnchor.visible = true
        this.$refs.uploadAnchor.fileList = []
      })
    },
    // 导出
    exportList() {

      // window.open(
      //     this.$http.adornUrl('/cs/table/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
      //     "_blank"
      // );
      const exportData = {
        ...this.dataForm,
        startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
        endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
        uid: this.dataForm.uid,
        page: this.pageIndex,
        limit: this.pageSize,
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.location.href = this.$http.adornUrl('/admin/walletTask/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`
    }

  },
}
</script>

<style scoped lang="scss">
.el-table {
  .el-button+.el-button {
    margin-left: 0;
  }
}
</style>
