<template>
  <el-dialog
      :title= "'导入任务'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" label-width="125px" :rules="dataRule">
      <el-form-item  label="导入excel文件" prop="file">
        <el-upload class="upload-demo" :action="ossUploadFileUrl" :on-success="handleFileSuccess" :show-file-list="true"
                 :on-remove="handleFileRemove" :on-preview="handlePreview" :file-list="fileList" :limit="1" accept=".xls">
           <el-button slot="trigger" size="small" type="primary" >
             上传文件
           </el-button>
        </el-upload>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { langOpt } from "@/views/common/commonOpt";
export default {
  created() {

    this.ossUploadFileUrl = this.$http.adornUrl(
        `/admin/walletTask/add?token=${this.$cookie.get("token")}`
    );
  },
  data() {

    return {
      visible: false,
      fileList: [],
      ossUploadFileUrl: "",
      dataRule: {},
      dataForm:[]
    }
  },
  methods: {

    handleAvatarRemove(res, file) {

    },
    handlePreview(res,file){

    },
    handleFileRemove(res,file){

    },

    handleFileSuccess(res, file) {
      if (res && res.code === 0) {
        this.$emit('refreshDataList')
        this.visible = false
      } else {
        this.$message.error('导入失败:' + res.msg)
      }
    }
  }
}

</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0 20px;
}

h3 {
  padding: 2vh 0;
  border-top: 1px solid rgba(184, 184, 184, 0.6)
}


.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 12vw;
  height: 12vw;
  line-height: 12vw;
  text-align: center;
}

.avatar {
  width: 12vw;
  height: 12vw;
  display: block;
}

.input-width {
  width: 30vw;
  margin-bottom: 2vh;
}
</style>